import * as React from 'react';
import { connect } from 'react-redux';
import { registerItemTemplatePart } from '@inwink/itemtemplate/templatecatalog';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import { Entities } from '@inwink/entities/entities';
import { CountDown } from '@@components/countdown';
import * as momenttimezone from 'moment-timezone';
import { States } from '../../services/services';
import { ItemTemplateProps } from './itemtemplate.props';

export interface IEventCountDownProps extends ItemTemplateProps {
    event?: Entities.IEventDetail;
    i18nHelper?: Entities.i18nHelper;
}

@withI18nHelper()
class EventCountDownComponent extends React.Component<IEventCountDownProps, any> {
    interval = null;

    constructor(props: IEventCountDownProps) {
        super(props);
        this.state = {
            startDate: this.props.event?.startDate && this.props.event?.timeZone
                ? momenttimezone.tz(this.props.event.startDate as string, this.props.event.timeZone)
                : null
        };
    }

    render() {
        if (this.state.startDate) {
            return <CountDown baseClassName="itemsbloccountdown" date={this.state.startDate} />;
        }
        return <div className="empty" />;
    }
}

function mapStateToProps(state: States.IAppState) {
    return {
        event: state.event && state.event.detail
    };
}

export const EventCountDown: new (any)
=> React.Component<IEventCountDownProps, any> = connect(mapStateToProps)(EventCountDownComponent as any) as any;

registerItemTemplatePart("eventcountdown", EventCountDown);
